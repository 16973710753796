import React, { useEffect } from "react"
import GatesWithForm from "../Resources/GatesWithForm"

const Popup = ({ data }) => {
  const {
    popup_title,
    popup_description,
    popup_button_link,
    popup_button_title,
    marketo_id,
    popup_timeout,
    popup_by_click,
  } = data

  let UIkit

  useEffect(() => {
    try {
      UIkit = require("uikit/dist/js/uikit")

      if (!popup_by_click) {
        setTimeout(() => UIkit.modal("#popup").toggle(), popup_timeout * 1000 || 10000)
      }
    } catch (e) {
      console.error("UIKit ERROR", e)
    }
  }, [])

  return (
    <div id="popup" className="uk-flex-top" data-uk-modal>
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
        <button className="uk-modal-close-default" type="button" data-uk-close />
        <div className="uk-grid uk-grid-divider uk-grid-medium" data-uk-grid>
          <div className="uk-width-1-1 uk-width-1-2@s uk-flex uk-flex-center uk-flex-middle uk-flex-column">
            {popup_title && <div dangerouslySetInnerHTML={{ __html: popup_title.html }} />}
            {popup_description && (
              <div dangerouslySetInnerHTML={{ __html: popup_description.html }} />
            )}
          </div>
          <div className="uk-width-1-1 uk-width-1-2@s">
            <GatesWithForm
              slogan=""
              marketoFormId={marketo_id || 1569}
              resourceKey={`popup_${marketo_id}`}
            >
              <h2 className="uk-text-bold">Thank you</h2>
              {popup_button_link && popup_button_link.url ? (
                <a
                  className="uk-button uk-button-medium uk-button-primary uk-border-pill uk-scrollspy-inview uk-animation-slide-right-medium"
                  href={popup_button_link.url}
                  target={popup_button_link.target}
                >
                  {popup_button_title}
                </a>
              ) : (
                <button
                  className="uk-button uk-button-medium uk-button-primary uk-border-pill uk-scrollspy-inview uk-animation-slide-right-medium"
                  onClick={() => UIkit.modal("#popup").hide()}
                >
                  {popup_button_title}
                </button>
              )}
            </GatesWithForm>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Popup

import React from "react"
import { graphql } from "gatsby"
import Hero from "../components/Product/Hero"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { withPreview } from "gatsby-source-prismic"
import { Image } from "../components/VindiciaComponents"

const SusbcriptionManagementDetail = ({ data }) => {
  const section = data.prismicSubscriptionManagement.data
  const page = data.prismicSubscriptionManagement.uid

  return (
    <Layout>
      <SEO
        title={section.seo_title}
        keywords={section.seo_keywords}
        description={section.seo_description}
      />
      <div>
        <Hero page={page} data={section} defaultBg="true" />
        <section className="uk-section">
          <div className="uk-container">
            <div className="uk-grid-large" data-uk-grid>
              <div className="uk-width-2-3@m">
                <div>
                  <h2>{section.headline}</h2>
                  <div dangerouslySetInnerHTML={{ __html: section.content.html }} />
                </div>
              </div>
              {section.sidebar_card &&
              section.sidebar_card.length &&
              section.sidebar_card[0].sidebar_cta_card.document !== null ? (
                <div className="uk-width-1-3@m">
                  <div>
                    {section.sidebar_card.map((card, i) => (
                      <div
                        key={`card-${i}`}
                        className="uk-card uk-card-small uk-card-default uk-background-muted uk-margin-large"
                      >
                        <div className="uk-card-media-top">
                          <Image
                            src={card.sidebar_cta_card.document.data.card_image_header.fluid.src}
                            srcSet={
                              card.sidebar_cta_card.document.data.card_image_header.fluid.srcSet
                            }
                            alt={section.short_title}
                            style={{
                              width: "100%",
                            }}
                          />
                        </div>
                        <div
                          className="uk-card-body"
                          dangerouslySetInnerHTML={{
                            __html: card.sidebar_cta_card.document.data.card_body_content.html,
                          }}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default withPreview(SusbcriptionManagementDetail)

export const subscriptionManagementQuery = graphql`
  query subscriptionManagementQuery($uid: String) {
    prismicSubscriptionManagement(uid: { eq: $uid }) {
      uid
      data {
        seo_title
        seo_keywords
        seo_description
        page_title
        short_title
        headline
        url_prefix
        second_url_prefix
        content {
          html
        }
        hero_banner_image {
          alt
          fluid(maxWidth: 1400) {
            ...GatsbyPrismicImageFluid_noBase64
          }
        }
        hero_title {
          html
          text
        }
        sidebar_card {
          sidebar_cta_card {
            document {
              ... on PrismicSidebarCtaCard {
                data {
                  card_body_content {
                    html
                  }
                  card_image_header {
                    fluid(maxWidth: 600) {
                      ...GatsbyPrismicImageFluid_noBase64
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
